import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6851a95b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "chat-service-item" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "cont" }
const _hoisted_6 = { class: "msg" }
const _hoisted_7 = { class: "time" }
const _hoisted_8 = {
  key: 0,
  class: "msg_count"
}

import { toRefs } from 'vue';
import { concatImg } from '@/filters/EnterFilters';

export default {
  props: { serviceList: Array, state: Object },
  setup(__props) {

const props = __props


const { serviceList, state } = toRefs(props)

return (_ctx, _cache) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(serviceList), (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['chat-service-box', { 'chat-service-active': item.u_id == _unref(state).pid }]),
      key: index + 'service',
      onClick: $event => (_ctx.$emit('openChat', item))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _unref(concatImg)(item.u_avatar),
          alt: "",
          class: "avatar"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", {
            class: "name",
            style: _normalizeStyle({ color: item.u_id < 10 ? '#c00' : '#333' })
          }, _toDisplayString(item.u_name  || '钢信宝用户'), 5),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(item.content||""), 1),
            _createElementVNode("p", _hoisted_7, _toDisplayString(item.created_at?item.created_at.substr(5, 5):""), 1)
          ]),
          (Number(item.count) && _unref(state).pid != item.u_id)
            ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(Number(item.count) > 99 ? "99+" : Number(item.count)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ], 10, _hoisted_1))
  }), 128))
}
}

}