

// =>时间变星期
export function toWeek(date) {
  let datelist = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六',]
  let newWeek = datelist[new Date(date).getDay()];
  return newWeek
}
// console.log(toweek("2021-12-30"));//周四
// console.log(toweek("2021-11-30 10:10:10"));//周二

export function formatDate(time) {
  var date = time ? new Date(time):new Date()
  var year = date.getFullYear(),
    month = date.getMonth() + 1,//月份是从0开始的
    day = date.getDate(),
    hour = date.getHours(),
    min = date.getMinutes(),
    sec = date.getSeconds();
  var newTime = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
  return newTime;
}

class DateHandler {
  now(time){
    return time?new Date(time).getTime():new Date().getTime()
  }
  nowToFormat(){
    //dataString是整数，否则要parseInt转换
		var time = new Date(dataString);
		var year = time.getFullYear();
		var month = time.getMonth()+1;
		var day = time.getDate();
		var hour = time.getHours();
		var minute = time.getMinutes();
		var second = time.getSeconds();
		return year+'-'+(month<10?'0'+month:month)+'-'+(day<10?'0'+day:day)+' '+(hour<10?'0'+hour:hour)+':'+(minute<10?'0'+minute:minute)+':'+(second<10?'0'+second:second)
  }
}


export default new DateHandler()
