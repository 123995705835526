import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-82101d32"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chat-item-time"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "msg-box" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 2 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML"]

import { concatImg } from '@/filters/EnterFilters';
import { ImagePreview } from 'vant';
import { ref, toRefs, nextTick, onUpdated } from 'vue'
import ChatOrder from '@/view/chat/ChatOrder.vue'


export default {
  props: { msgList: Array, info: Object, user_info: Object },
  setup(__props) {

const props = __props


const { msgList, info, user_info } = toRefs(props)

//# 绑定节点
const bodyRef = ref(null)

//# 绑定节点
const bool = (item) => item.msg.includes(`class="upload-img"`) || item.msg.includes(`max-width:400px`)

//# 绑定节点
const msgBox = (e) => e.target.src && e.target.className == "" && ImagePreview({ images: [e.target.src], closeable: true, showIndex: false })

// # 滚动条沉底
function scrollBottom() {
    if (bodyRef.value) {
        nextTick(() => {
            bodyRef.value.scrollTop = bodyRef.value.scrollHeight;
        })
    }
}
onUpdated(() => { scrollBottom() })

return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "chat-message",
    ref_key: "bodyRef",
    ref: bodyRef
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(msgList), (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index + 'chat',
        class: "chat-item"
      }, [
        (item.system)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(item.msg), 1))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass([item.u_id == _unref(info).uid ? 'chat-item-right' : 'chat-item-left', 'chat-item-box'])
            }, [
              (item.u_id == _unref(info).uid)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _unref(concatImg)(_unref(info).avatar),
                    alt: "",
                    class: "avatar"
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _unref(concatImg)(_unref(info).p_avatar),
                    alt: "",
                    class: "avatar"
                  }, null, 8, _hoisted_3)),
              _createElementVNode("div", _hoisted_4, [
                (item.u_id == _unref(info).uid)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      style: {"color":"#999","font-size":"12px","padding":"0  10px 5px 10px","text-align":"right"},
                      innerHTML: _unref(user_info).name
                    }, null, 8, _hoisted_5))
                  : (_openBlock(), _createElementBlock("p", {
                      key: 1,
                      style: {"color":"#999","font-size":"12px","padding":"0  10px 5px 10px"},
                      innerHTML: item.p_name
                    }, null, 8, _hoisted_6)),
                (item.media == 6)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(ChatOrder, {
                        orderInfo: JSON.parse(item.msg)
                      }, null, 8, ["orderInfo"])
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      (bool(item))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: item.msg,
                            class: "chat-item-msg",
                            style: {"padding":"0","background":"none"},
                            onClick: _cache[0] || (_cache[0] = $event => (msgBox($event)))
                          }, null, 8, _hoisted_8))
                        : (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "chat-item-msg",
                            innerHTML: item.msg
                          }, null, 8, _hoisted_9))
                    ], 64))
              ])
            ], 2))
      ]))
    }), 128))
  ], 512))
}
}

}